import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Container } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import NavLogo from '../svg/roulette-wheel-3color.svg';

const HeaderNav = ({ path, translate, isHomePage }) => {
  const homeLink = translate.language === 'en' ? '/' : `/${translate.language}/`;
  const homePageClass = isHomePage ? 'homePage' : '';
  return (
    <header className={`navBar ${homePageClass}`}>
      <Container>
        <div className="navLogo">
          <GatsbyLink to={homeLink}>
            <NavLogo />
            <h3>Netflix Roulette</h3>
          </GatsbyLink>
        </div>

        <nav className="navItemsGroup">
          <span className="navItem navLinkAnchor">
            <GatsbyLink to={`${homeLink}#whats-new`}>{translate.nav.blog}</GatsbyLink>
          </span>

          <div className="miltiLangDiv">
            <Dropdown>
              <Dropdown.Toggle id="language-select">
                <svg height="24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M12 0c7.18 0 12 4.82 12 12s-4.82 12-12 12S0 19.18 0 12 4.82 0 12 0zm-1.84 1.92a9.41 9.41 0 0 0-8.33 8.93l2.49-.11L5.9 12.9l-1.18 6.64A10.248 10.248 0 0 0 12 22.22c6.14 0 10.22-4.08 10.22-10.22a11.692 11.692 0 0 0-.8-4.28l-1 .43-3.38-4.38-2.88.77zm.02 3.46l2.75 1.25h3.99l2.15 3.79-1.59 2.4V15h-1.45v1.53c-.002 1.073-.071 1.47-1.65 1.47h-1.1v-4.61h-1.95v-3H7.64l-.56-2.24 3.1-2.77z" fill="#FFF"></path></svg>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as="div"><GatsbyLink to="/de/">Deutsch</GatsbyLink></Dropdown.Item>
                <Dropdown.Item as="div"><GatsbyLink to="/">English</GatsbyLink></Dropdown.Item>
                <Dropdown.Item as="div"><GatsbyLink to="/es/">Español</GatsbyLink></Dropdown.Item>
                <Dropdown.Item as="div"><GatsbyLink to="/pt/">Português</GatsbyLink></Dropdown.Item>
                <Dropdown.Item as="div"><GatsbyLink to="/tr/">Türkçe</GatsbyLink></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </nav>
      </Container>
    </header>
  );
};

export default HeaderNav;
