exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-de-index-jsx": () => import("./../../../src/pages/de/index.jsx" /* webpackChunkName: "component---src-pages-de-index-jsx" */),
  "component---src-pages-es-index-jsx": () => import("./../../../src/pages/es/index.jsx" /* webpackChunkName: "component---src-pages-es-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pt-index-jsx": () => import("./../../../src/pages/pt/index.jsx" /* webpackChunkName: "component---src-pages-pt-index-jsx" */),
  "component---src-pages-tr-index-jsx": () => import("./../../../src/pages/tr/index.jsx" /* webpackChunkName: "component---src-pages-tr-index-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */)
}

