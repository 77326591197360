import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Header from '../components/Header';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import { getSrc } from 'gatsby-plugin-image';
import lang from '../data/translations.json';

const queryClient = new QueryClient();

const MasterLayout = ({ children, path }) => {
  const metaTitle = children?.props?.data?.post?.title;
  const metaDesc = children?.props?.data?.post?.shortDescription;
  const metaImage = children?.props?.data?.post?.squareImage;
  const pathClean = path.replaceAll('/', '');
  const translate = lang[pathClean] ? lang[pathClean] : lang.en;
  const isHomePage = children?.type?.name === 'HomePage';
  return (
    <>
      <Header meataCanonical={path} metaTitle={metaTitle || translate.meta.metaTitle} metaDesc={metaDesc || translate.meta.metaDesc} metaImage={metaImage && getSrc(metaImage)} isHomePage={isHomePage} />
      <Nav path={path} translate={translate} isHomePage={isHomePage} />
      <QueryClientProvider client={queryClient}>
        {children}
        {/* <ReactQueryDevtools /> */}
      </QueryClientProvider>
      <Footer />
    </>
  );
};

export default MasterLayout;
