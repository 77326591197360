import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-scroll';

const Footer = () => {
  return (
    <footer className="footer navbar-static-bottom">
      <Container>
        <span className="back-to-top">
          <Link to="main" smooth duration={500}>
            <i className="fa fa-angle-up fa-2x" aria-hidden="true" />
          </Link>
        </span>
        <hr />
        <p className="footer__text">Designed and Developed by{' '}<a href="https://github.com/davidbbe" target="_blank">David Beauchamp</a></p>
      </Container>
    </footer>
  );
};

export default Footer;
