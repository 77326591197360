import React from 'react';
import { Helmet } from 'react-helmet';

const Header = ({ meataCanonical, metaTitle, metaDesc, metaImage, isHomePage }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{ metaTitle }</title>
      <html lang="en" />
      <meta name="description" content={ metaDesc } />
      <meta name="keywords" content="Netflix roulette, recomendations, movies, TV" />
      <link rel="canonical" href={ `https://tvroulette.app${meataCanonical}` } />

      {isHomePage && <link href="https://tvroulette.app/" hreflang="x-default" rel="alternate" />}
      {isHomePage && <link href="https://tvroulette.app/" hreflang="en" rel="alternate"></link>}
      {isHomePage && <link href="https://tvroulette.app/es/" hreflang="es" rel="alternate"></link>}
      {isHomePage && <link href="https://tvroulette.app/tr/" hreflang="tr" rel="alternate"></link>}
      {isHomePage && <link href="https://tvroulette.app/de/" hreflang="de" rel="alternate"></link>}
      {isHomePage && <link href="https://tvroulette.app/pt/" hreflang="pt" rel="alternate"></link>}

      { /* Open Graph */ }
      <meta property="og:url" content={ `https://tvroulette.app${meataCanonical}` } />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={ metaTitle } />
      <meta property="og:image" content={ metaImage } />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="900" />
      <meta property="og:image:height" content="900" />
      <meta property="og:description" content={ metaDesc } />
      <meta property="og:locale" content="en_US" />
      <meta property="fb:app_id" content="tvroulette" />

      { /* Twitter Card */ }
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:url" content={ `https://tvroulette.app${meataCanonical}` } />
      <meta name="twitter:title" content={ metaTitle } />
      <meta name="twitter:description" content={ metaDesc } />
      <meta name="twitter:image" content={ metaImage } />
      <meta name="twitter:site" content="@tvrouletteapp" />

      { /* Slick Carousel css(cant be imported) */ }
      <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css" defer />
      <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css" defer />
    </Helmet>
  );
};

export default Header;
